import { AppBar, Avatar, Box, Button, Drawer, IconButton, List, ListItem, Popover, Stack, Typography } from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { useCallback, useContext, useMemo, useState } from 'react'
import { IoMenuSharp, IoReorderThreeOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { baseURL, serviceName } from 'src/config'
import { useAuth } from 'src/hooks/useAuth'
import { ClientContext } from '../providers/ClientProvider'

type Props = {
  onOpenDrawer: () => void
}

export const Header = ({ onOpenDrawer }: Props) => {
  const { googleLogin, logout } = useAuth()
  const [user, isLoading] = useContext(AuthContext)
  const { client, clientMembers } = useContext(ClientContext)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  const currentMember = useMemo(() => {
    return clientMembers.find((m) => m.id === user?.uid)
  }, [clientMembers, user])

  const handleLogout = useCallback(() => {
    logout()
    window.location.reload()
  }, [logout])

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#fff' }}>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" py={1} pl={1} pr={2}>
        <Box>
          <Link to={client ? `/clients/${client.id}/devices` : '/lp'} style={{ textDecoration: 'none' }}>
            <Typography color={(theme) => theme.palette.primary.main} variant="h3">
              {serviceName}
            </Typography>
          </Link>
        </Box>
        {isLoading && (
          <Box>
            <Button disabled variant="contained">
              ...
            </Button>
          </Box>
        )}
        {!isLoading && user && currentMember && (
          <Box display="flex" alignItems="center">
            <PopupState variant="popover" popupId="account-pop">
              {(popupState) => (
                <>
                  <Box mr={1}>
                    <IconButton {...bindTrigger(popupState)}>
                      <Avatar src={currentMember.iconURL ?? `${baseURL}/user.png`} />
                    </IconButton>
                  </Box>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Stack spacing={0}>
                      {currentMember.name && (
                        <Box padding={2}>
                          <Link to={`/clients/${client?.id}/profile`} onClick={popupState.close}>
                            <Typography>{currentMember.name}</Typography>
                          </Link>
                        </Box>
                      )}
                      {user.email && (
                        <Box padding={2}>
                          <Typography>{user.email}</Typography>
                        </Box>
                      )}
                      <Box pb={0.5} display="flex" justifyContent="center" alignItems="center">
                        <Button onClick={handleLogout} fullWidth>
                          sign out
                        </Button>
                      </Box>
                    </Stack>
                  </Popover>
                </>
              )}
            </PopupState>
            <Box sx={{ display: { xs: 'block', md: 'none' } }} mr={1}>
              <IconButton onClick={onOpenDrawer}>
                <IoReorderThreeOutline />
              </IconButton>
            </Box>
          </Box>
        )}
        {!isLoading && !currentMember && (
          <>
            <Box
              mr={2}
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
              gap={2}
            >
              <Button component={Link} variant="contained" to="/inquiry/register">
                ご利用申請
              </Button>
              <Button onClick={googleLogin} variant="contained">
                ログイン
              </Button>
            </Box>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
              mr={1}
            >
              <IconButton onClick={() => setOpenDrawer((v) => !v)}>
                <IoMenuSharp />
              </IconButton>
            </Box>
            <Drawer
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              anchor="right"
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
            >
              <Box width={120} mt={8}>
                <List>
                  <ListItem key="application">
                    <Link to="/inquiry/register">ご利用申請</Link>
                  </ListItem>
                </List>
                <List>
                  <ListItem key="login">
                    <a onClick={googleLogin}>ログイン</a>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </>
        )}
      </Box>
    </AppBar>
  )
}
