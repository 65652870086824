import * as Sentry from '@sentry/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BaseLayout } from 'src/components/layouts/BaseLayout'
import { GlobalProductStateProvider } from 'src/components/providers/GlobalProductProvider'
import { LoginRequiredProvider } from 'src/components/providers/LoginRequiredProvider'
import { ScrollReset } from 'src/components/ScrollReset'
import { BasicRouterPage } from 'src/pages/BasicRouterPage'
import { ClientPage } from 'src/pages/Client'
import { ClientDevice } from 'src/pages/ClientDevice'
import { ClientDevices } from 'src/pages/ClientDevices'
import { ClientInquiriesPage } from 'src/pages/ClientInquiriesPage'
import { ClientInquiryPage } from 'src/pages/ClientInquiryPage'
import { Clients } from 'src/pages/Clients'
import { DeviceProductPage } from 'src/pages/DeviceProductPage'
import { NotFound } from 'src/pages/error/NotFound'
import { Tokai0001 } from 'src/pages/fixed/deviceTypes/Tokai0001'
import { Inquiry } from 'src/pages/fixed/Inquiry'
import { InquiryRegister } from 'src/pages/fixed/Inquiry/InquiryRegister'
import { InquiryRegisterWithCode } from 'src/pages/fixed/Inquiry/InquiryRegisterWithCode'
import { LP } from 'src/pages/LP'
import { NotificationPage } from 'src/pages/Notification'
import { NotificationsPage } from 'src/pages/Notifications'
import { ProfileSettingPage } from 'src/pages/ProfileSettingPage'
import { TermsOfUse } from 'src/pages/public/TermsOfUse'
import { TokuteishouTorihikihou } from 'src/pages/public/TokuteishouTorihikihou'
import { RegisterClientDevice } from 'src/pages/RegisterClientDevice'
import { AnonymousOnlyProvider } from '../providers/AnonymousOnlyProvider'
import { ClientProvider } from '../providers/ClientProvider'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const RouteConfig = () => {
  return (
    <BrowserRouter>
      <SentryRoutes>
        {/* <Suspense> */}
        <Route path="/" element={<ClientProvider />}>
          <Route path="/" element={<BaseLayout />}>
            <Route path="/" element={<BasicRouterPage />} />
            <Route path="/" element={<LoginRequiredProvider />}>
              <Route path="/" element={<GlobalProductStateProvider />}>
                <Route path="/devices/register/:id" element={<RegisterClientDevice />} />
                {/* clients */}
                <Route path="/clients" element={<Clients />} />
                {/* ここは :clientIdにしない */}
                <Route path="/clients/:id" element={<ClientPage />} />
                <Route path="/clients/:clientId/devices" element={<ClientDevices />} />
                <Route path="/clients/:clientId/devices/register/:id" element={<RegisterClientDevice />} />
                <Route path="/clients/:clientId/devices/:id" element={<ClientDevice />} />
                <Route path="/clients/:clientId/notifications" element={<NotificationsPage />} />
                <Route path="/clients/:clientId/notifications/:id" element={<NotificationPage />} />
                <Route path="/clients/:clientId/inquiries" element={<ClientInquiriesPage />} />
                <Route path="/clients/:clientId/inquiries/:id" element={<ClientInquiryPage />} />
                <Route path="/clients/:clientId/orders" element={<DeviceProductPage />} />
                <Route path="/clients/:clientId/profile" element={<ProfileSettingPage />} />
              </Route>
            </Route>
            <Route path="/" element={<AnonymousOnlyProvider />}>
              <Route path="/lp" element={<LP />} />
            </Route>
            <Route path="/deviceTypes/lte-model-0001" element={<Tokai0001 />} />
            <Route path="/inquiry/register" element={<InquiryRegister />} />
            <Route path="/invitation" element={<InquiryRegisterWithCode />} />
            <Route path="/inquiry" element={<Inquiry />} />
            <Route path="/public/termsOfUse" element={<TermsOfUse />} />
            <Route path="/public/tokuteishoutorihikihou" element={<TokuteishouTorihikihou />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        {/* </Suspense> */}
      </SentryRoutes>
      <ScrollReset />
    </BrowserRouter>
  )
}
