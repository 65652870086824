import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { IsTrappingStatus, OperationStatus } from '@maru44/huntre-utils/src/models/device'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { StatusChip } from 'src/components/atoms/StatusChip'
import { PopoverWhatIsTurnedOff } from 'src/components/device/PopoverIsTurnedOff'
import { formatTimestamp } from 'src/utils/firebase'

// TODO add pagination
type DeviceTableProps = {
  clientDevices: ClientDeviceWithDevice[] | undefined
}

export const ClientDeviceTable = ({ clientDevices }: DeviceTableProps) => {
  return (
    <>
      <TableContainer component={Paper} elevation={6}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">デバイス</Typography>
              </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle1">通知</Typography>
                  <PopoverWhatIsTurnedOff />
                </Box>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">罠状況 / 異常</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">最終通信日時</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientDevices?.map((d) => (
              <DeviceRow device={d} key={d.device.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

type DeviceRowProps = {
  device: ClientDeviceWithDevice
}

const DeviceRow = ({ device }: DeviceRowProps) => {
  const trappingStatus = useMemo((): IsTrappingStatus => {
    if (device.device.isTrapping) return IsTrappingStatus.Trapping
    return IsTrappingStatus.NotTrapping
  }, [device.device.isTrapping])

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Link to={`${device.clientDevice.id}`}>{device.clientDevice.deviceId}</Link>
      </TableCell>
      <TableCell>
        <Typography>{device.clientDevice.isTurnedOff ? 'off' : 'on'}</Typography>
      </TableCell>
      <TableCell>
        {!device.clientDevice.isTurnedOff ? (
          <StatusChip status={device.device.operationStatus === OperationStatus.Abnormal ? device.device.operationStatus : trappingStatus} />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>{device.device.lastMessageReceivedAt && <Typography>{formatTimestamp(device.device.lastMessageReceivedAt)}</Typography>}</TableCell>
    </TableRow>
  )
}
