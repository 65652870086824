import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { useCallback } from 'react'
import { firebaseAuth } from 'src/utils/firebase'

export const useAuth = () => {
  const googleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider()
    return await signInWithPopup(firebaseAuth, provider)
  }, [])

  const logout = useCallback(() => {
    return signOut(firebaseAuth)
  }, [])

  return {
    googleLogin,
    logout,
  }
}
