import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useCallback, useContext, useMemo, useState } from 'react'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { CreateClientDeviceDialog } from 'src/components/dialogs/device/CreateClientDeviceDialog'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useClientDevices } from 'src/hooks/clientDevice/useClientDevices'
import { ClientDeviceTable } from 'src/pages/ClientDevices/DeviceTable'
import useSWR from 'swr'
import { ErrorPage } from '../ErrorPage'
import { DevicePositionsMap } from './DevicePositionsMap'

export const ClientDevices = () => {
  const { client } = useContext(ClientContext)

  const { listClientDevicesWithDevice, createClientDevice } = useClientDevices(client?.id ?? '')
  const { data, mutate, error } = useSWR(`clients/${client?.id}/clientDevices`, client ? () => listClientDevicesWithDevice() : null)

  const [openCreate, setOpenCreate] = useState(false)

  const handleCreateClientDevice = useCallback(
    async ({ deviceId }: { deviceId: string }) => {
      await createClientDevice({
        deviceId,
      })
    },
    [createClientDevice, client?.id]
  )

  const positions = useMemo((): ClientDeviceWithDevice[] => {
    if (!data) {
      return []
    }
    return data.filter((v) => !!v.device.position)
  }, [data])

  return (
    <>
      <Box>
        <Breads breadIds={['home']} />
        <Stack spacing={2}>
          <Typography variant="h5">デバイス一覧</Typography>
          <Typography>登録したデバイス一覧です</Typography>
          {data && (
            <>
              <ClientDeviceTable clientDevices={data} />
              <Box display="flex" justifyContent="end">
                <Button variant="contained" onClick={() => setOpenCreate(true)}>
                  デバイスの登録
                </Button>
              </Box>
              {positions.length > 0 && (
                <Box>
                  <Typography variant="h6">位置情報</Typography>
                  <DevicePositionsMap devices={positions} />
                </Box>
              )}
            </>
          )}
          {error && <ErrorPage exception={error} />}
        </Stack>
      </Box>
      <CreateClientDeviceDialog open={openCreate} onClose={() => setOpenCreate(false)} listMutate={mutate} onSubmit={handleCreateClientDevice} />
    </>
  )
}
